import * as S from "./TeacherCardInfo.style";
import { TeacherCardProps } from "@models";
import Flex from "@atoms/Flex/Flex";
import Text from "@atoms/Text/Text";
import Icon from "@atoms/Icon/Icon";
import Spacer from "@atoms/Spacer/Spacer";
import CategoryBox from "@molecules/Category/CategoryBox";
import CoinGroup from "@molecules/Group/CoinGroup";

interface TeacherInfoProps {
  teacher: TeacherCardProps;
  size: "small" | "large";
}

const TeacherInfo = ({ teacher, size }: TeacherInfoProps) => {
  return (
    <S.TeacherInfoWrapper
      size={size}
      direction="column"
      justify="space-between"
    >
      <Flex justify="center" align="center">
        <Text
          typography={size === "large" ? "t16" : "t12"}
          fontWeight={600}
          color={teacher.status === "상담가능" ? "main" : "black33"}
          style={{
            position: "absolute",
            top: "10px",
            left: "24px",
          }}
        >
          {teacher.status}
        </Text>

        <Text typography={size === "large" ? "t22" : "t18"} fontWeight={600}>
          {teacher.name}
        </Text>

        {/* <CoinGroup teacher={teacher} size={size} /> */}
        <Flex
          align="center"
          style={{
            position: "absolute",
            right: "13px",
            top: "10px",
          }}
        >
          <Icon
            name="IconReviewStar"
            width={size === "large" ? "1.9rem" : "13px"}
            height={size === "large" ? "1.7rem" : "12px"}
          />
          <Spacer space="0.44rem" direction="horizontal" />
          <Text typography={size === "large" ? "t16" : "t12"} fontWeight={600}>
            {teacher.rating}
          </Text>
          <Text
            typography={size === "large" ? "t16" : "t10"}
            fontWeight={600}
            color="grey53"
          >
            (+100)
          </Text>
        </Flex>
      </Flex>

      {/* <Spacer space="0.4rem" /> */}
      <Flex
        justify="center"
        style={{
          marginTop: "0.5rem",
        }}
      >
        <Text typography={size === "large" ? "t16" : "t12"} color="grey53">
          {teacher.id}번
        </Text>
      </Flex>

      <Spacer space="0.5rem" />

      <Flex justify="space-between" align="center">
        <Flex
          gap={0.5}
          style={{
            flexWrap: "wrap",
          }}
        >
          {teacher.tags.map((tag, i) => (
            <CategoryBox key={i} size={size}>
              <Text
                typography={size === "large" ? "t16" : "t12"}
                color="black33"
              >
                {tag}
              </Text>
            </CategoryBox>
          ))}
        </Flex>

        <CoinGroup teacher={teacher} size={size} justify="end" />
      </Flex>

      <Spacer space="1rem" />

      <Flex gap={1}>
        {teacher.categories.map((category, i) => (
          <Text
            typography={size === "large" ? "t20" : "t12"}
            color="black33"
            key={i}
          >
            {category}
          </Text>
        ))}
      </Flex>
    </S.TeacherInfoWrapper>
  );
};

export default TeacherInfo;

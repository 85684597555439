import * as S from "./Dimmed.style";

function Dimmed({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return <S.Dimmed onClick={onClick}>{children}</S.Dimmed>;
}

export default Dimmed;

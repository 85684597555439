import styled from "@emotion/styled";

import { TextProps } from "@atoms/Text/Text";
import { colors } from "@style/colorPalette";
import { typographyMap } from "@style/typography";

export const Text = styled.span<TextProps>(
  ({ color = "black", textAlign, fontWeight, lineHeight }) => ({
    color: colors[color], // var(--red)
    textAlign,
    fontWeight: fontWeight || 400,
    display: "inline-block",
    lineHeight: lineHeight || "90%",
    width: "auto",
    whiteSpace: "nowrap",
  }),
  ({ typography = "t16" }) => typographyMap[typography]
);

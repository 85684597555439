import { popupAtom, PopupType } from "@atom/modalAtom";
import { useRecoilState } from "recoil";

export const usePopup = () => {
  const [activePopup, setActivePopup] = useRecoilState(popupAtom);

  const openPopup = (popupType: PopupType) => setActivePopup(popupType);
  const closePopup = () => setActivePopup(null);

  return {
    activePopup,
    openPopup,
    closePopup,
  };
};

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Dimmed from "../Dimmed/Dimmed";

interface PopupProps {
  children: React.ReactNode;
  togglePopup: () => void;
}

function Popup({ children, togglePopup }: PopupProps) {
  const clickOutSide = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      togglePopup();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";

    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, []);

  return ReactDOM.createPortal(
    <Dimmed onClick={clickOutSide}>{children}</Dimmed>,
    document.getElementById("root-portal") as HTMLElement
  );
}

export default Popup;

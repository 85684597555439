import Button from "@/components/atoms/Button/Button";
import Division from "@/components/atoms/Division/Division";
import Flex from "@/components/atoms/Flex/Flex";
import Icon from "@/components/atoms/Icon/Icon";
import Image from "@/components/atoms/Image/Image";
import Spacer from "@/components/atoms/Spacer/Spacer";
import Text from "@/components/atoms/Text/Text";
import { TeacherCardProps } from "@/models";
import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";

const CounselLayout = () => {
  const { teacherId } = useParams();
  const navigate = useNavigate();

  const teacher: TeacherCardProps = {
    id: 1,
    name: "심채운0",
    status: "상담중",
    rating: "4.2",
    price: "1,500",
    tags: ["연애", "이별", "속마음", "연애", "이별"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인", "#갓신내림", "#단호박 공수"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  };

  return (
    <CounselContainer>
      <Flex
        align="center"
        justify="center"
        style={{
          position: "relative",
        }}
      >
        <Icon
          name="IconClose"
          width="3.5rem"
          height="3.5rem"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onClick={() => navigate(-1)}
        />
        <Text typography="t28" fontWeight={400} color="main">
          상담마스터 상담하기
        </Text>
      </Flex>

      <Spacer space="1.2rem" />
      <Division color="grey94" />

      <Spacer space="1rem" />
      {/* 선생님 정보 컴포넌트화 */}
      <TeacherWrapper>
        <Flex gap={1} align="center">
          <Image
            src={teacher.img}
            alt={`${teacherId}선생님`}
            width="9rem"
            height="9rem"
            borderRadius="50%"
            objectFit="cover"
          />
          <Flex direction="column" gap={0.5}>
            <Flex align="center" gap={0.8}>
              {/* kind 뱃지 */}
              <Flex
                align="center"
                justify="center"
                style={{
                  background: colors.red95,
                  padding: "0.3rem 0.8rem",
                  borderRadius: "0.5rem",
                }}
              >
                <Text typography="t20" color="red50" fontWeight={500}>
                  {teacher.kind}
                </Text>
              </Flex>
              <Text typography="t20" color="grey53" fontWeight={500}>
                {teacherId}번
              </Text>
            </Flex>

            <Text typography="t28" color="main" fontWeight={600}>
              {teacher.name}
            </Text>
          </Flex>
        </Flex>
      </TeacherWrapper>

      <Spacer space="3.3rem" />
      <Flex justify="space-between" align="center">
        <Flex gap={0.7}>
          <Text typography="t24" color="main" fontWeight={700}>
            전화상담(선불)이용하기
          </Text>
          <Icon name="IconInfo" width="2.4rem" height="2.4rem" />
        </Flex>

        <Flex>
          <Flex align="center" gap={0.4}>
            <Text typography="t28" color="main" fontWeight={400}>
              {teacher.price}
            </Text>
            <Flex>
              <Icon name="IconCoin" width="2.3rem" height="2.3rem" />
            </Flex>
            <Text typography="t20" color="grey53">
              30초
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Spacer space="1.1rem" />

      {/* 시간 */}
      <Flex
        direction="column"
        align="center"
        gap={1}
        style={{
          padding: "2.7rem 0 2.1rem",
          borderRadius: "1rem",
          background: colors.ivory95,
        }}
      >
        <Flex direction="column" align="center" gap={0.5}>
          <Text typography="t20" color="grey53" fontWeight={600}>
            MY 코인
          </Text>
          <Text typography="t40" color="main" fontWeight={700}>
            130
          </Text>
        </Flex>

        <Icon name="IconHourGlass" width="2.4rem" height="2.4rem" />

        <Flex direction="column" align="center" gap={0.5}>
          <Text typography="t20" color="grey53" fontWeight={600}>
            상담 가능 시간
          </Text>
          <Text typography="t40" color="main" fontWeight={700}>
            20분
          </Text>
        </Flex>
      </Flex>
      <Spacer space="1.3rem" />

      <Button
        full
        color="pink"
        borderRadius="1rem"
        fontWeight={700}
        typography="t24"
        weak
        style={{
          height: "7rem",
          padding: 0,
        }}
      >
        코인충전하기
      </Button>
      <Spacer space="1rem" />

      <Button
        full
        color="pink"
        borderRadius="1rem"
        fontWeight={700}
        typography="t24"
        style={{
          height: "7rem",
          padding: 0,
        }}
      >
        선불 전화상담하기
      </Button>
      <Spacer space="1.3rem" />

      <Flex align="center" justify="center">
        <Text typography="t20" color="grey56" fontWeight={400}>
          전화상담하기 버튼을 누르면, 레아 상담마스터와 바로 연결됩니다.
        </Text>
      </Flex>
      <Spacer space="2.8rem" />
      <Division color="grey94" />

      <Spacer space="2.8rem" />
      <Flex justify="space-between" align="center">
        <Flex gap={0.7}>
          <Text typography="t24" color="main" fontWeight={700}>
            전화상담(후불)이용하기
          </Text>
          <Icon name="IconInfo" width="2.4rem" height="2.4rem" />
        </Flex>

        <Flex>
          <Flex align="center" gap={0.4}>
            <Text typography="t28" color="main" fontWeight={400}>
              {teacher.price}
            </Text>

            <Text typography="t20" color="grey53">
              30초
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Spacer space="1.3rem" />
      <Button
        full
        color="pink"
        borderRadius="1rem"
        fontWeight={700}
        typography="t24"
        style={{
          height: "7rem",
          padding: 0,
        }}
      >
        후불 전화상담하기 / 060-700-7713
      </Button>
      <Spacer space="1.3rem" />

      <Flex justify="center">
        <Text
          typography="t20"
          color="grey56"
          fontWeight={400}
          lineHeight="2.8rem"
          style={{
            width: "50rem",
            whiteSpace: "wrap",
            textAlign: "center",
          }}
        >
          전화상담하기 버튼을 누른 후, 안내멘트에 따라 고유번호
          <strong>{teacherId}</strong>을 입력하시면 레아 상담마스터와 바로
          연결됩니다.
        </Text>
      </Flex>
    </CounselContainer>
  );
};

export default CounselLayout;

const CounselContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  padding: 4rem 0;
`;

const TeacherWrapper = styled.div`
  padding: 3.2rem 3rem;
  border-radius: 1rem;
  height: 15rem;
  box-sizing: border-box;
  background-color: skyblue;
  border: 1px solid ${colors.grey90};
  background-color: ${colors.ivory95};
`;

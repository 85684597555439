import { ColorsType } from '@style/colorPalette';
import * as S from './Circle.style';

export interface CircleProps {
  width?: string;
  height?: string;
  color?: ColorsType;
}

const Circle = ({ ...props }: CircleProps) => {
  return <S.Circle {...props} />;
};

export default Circle;

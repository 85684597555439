import Flex from "@atoms/Flex/Flex";
import Icon from "@atoms/Icon/Icon";
import Text from "@atoms/Text/Text";
import { TeacherCardProps } from "@models";
import { CSSProperties } from "react";

interface CoinGroupProps {
  teacher: TeacherCardProps;
  size: "small" | "large";
  justify: CSSProperties["justifyContent"];
}

function CoinGroup({ teacher, size, justify }: CoinGroupProps) {
  return (
    <Flex
      align="center"
      justify={justify}
      full
      style={{
        flex: 1,
        marginLeft: "0.3rem",
        height: "2.3rem",
      }}
      gap={0.4}
    >
      <Text typography={size === "large" ? "t22" : "t18"}>{teacher.price}</Text>
      <Flex>
        <Icon
          name="IconCoin"
          width={size === "large" ? "2.3rem" : "1.5rem"}
          height={size === "large" ? "2.3rem" : "1.5rem"}
        />
      </Flex>
      <Text typography={size === "large" ? "t18" : "t12"} color="grey53">
        30초
      </Text>
    </Flex>
  );
}

export default CoinGroup;

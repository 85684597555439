import styled from "@emotion/styled";
import React, { useState } from "react";
import Flex from "../atoms/Flex/Flex";
import Icon from "../atoms/Icon/Icon";
import Text from "../atoms/Text/Text";
import Spacer from "../atoms/Spacer/Spacer";
import Division from "../atoms/Division/Division";
import { colors } from "@/style/colorPalette";
import { useNavigate } from "react-router-dom";
import Circle from "../atoms/Circle/Circle";
import Button from "../atoms/Button/Button";

const ChargeCoinPage = () => {
  const [activeTab, setActiveTab] = useState<"일반" | "자동">("일반");

  const navigate = useNavigate();

  const [selectedCost, setSelectedCost] = useState<string | null>(null);

  //   const [payMethod, setPayMethod] = useState<"만월페이" | "일반결제">(
  //     "만월페이"
  //   );

  const generalChargeOptions = [
    {
      coin: "15,000",
      plusCoin: "",
      percent: 0,
    },
    {
      coin: "30,000",
      plusCoin: "",
      percent: 0,
    },
    {
      coin: "50,000",
      plusCoin: "500",
      percent: 1,
    },
    {
      coin: "100,000",
      plusCoin: "2,000",
      percent: 2,
    },
    {
      coin: "200,000",
      plusCoin: "6,000",
      percent: 3,
    },
    {
      coin: "500,000",
      plusCoin: "25,000",
      percent: 5,
    },
  ];

  return (
    <ChargeContainer>
      <Flex
        align="center"
        justify="center"
        style={{
          position: "relative",
        }}
      >
        <Icon
          name="IconClose"
          width="3.5rem"
          height="3.5rem"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onClick={() => navigate(-1)}
        />
        <Text typography="t28" fontWeight={400} color="main">
          코인 충전하기
        </Text>
      </Flex>

      <Spacer space="1.2rem" />
      <Division color="grey94" />
      <Spacer space="1rem" />

      <Flex
        style={{
          borderRadius: "1rem",
          border: `1px solid ${colors.grey90}`,
          background: colors.ivory95,
          padding: "2.6rem 0 2.6rem 3.4rem",
        }}
      >
        <Flex align="start" gap={0.8}>
          <Icon name="IconInfo2" />
          <Flex direction="column" gap={1}>
            <Text typography="t20" color="grey53" fontWeight={400}>
              만월코인은 만월사이트에서만 사용 가능한 상담 이용 코인입니다.
            </Text>
            <Text typography="t20" color="grey53" fontWeight={400}>
              코인은 상담 서비스 이용 시 부분환불이 불가능하며 환금성이
              없습니다.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Spacer space="2.3rem" />

      <Flex
        align="center"
        gap={0.4}
        style={{
          paddingBottom: "1.3rem",
        }}
      >
        <Text typography="t20" color="grey53" fontWeight={400}>
          나의 코인
        </Text>
        <Icon name="IconCoin" width="2.3rem" height="2.3rem" />
        <Text typography="t28" color="main" fontWeight={500}>
          12,000
        </Text>
      </Flex>
      <Division color="grey94" />

      <Flex direction="column">
        <Flex>
          <Tab
            isActive={activeTab === "일반"}
            onClick={() => setActiveTab("일반")}
          >
            일반 충전
          </Tab>
          <Tab
            isActive={activeTab === "자동"}
            onClick={() => setActiveTab("자동")}
          >
            자동 충전
          </Tab>
        </Flex>

        {/* 일반 충전 내용 */}
        <Flex
          direction="column"
          style={{
            padding: "2.6rem 3.4rem 3rem",
          }}
        >
          <Flex justify="space-between">
            <Text typography="t20" color="main" fontWeight={700}>
              결제금액
            </Text>
            <Text typography="t16" color="grey53" fontWeight={400}>
              *VAT별도
            </Text>
          </Flex>

          <Spacer space="1.5rem" />

          <Flex direction="column" gap={0.8}>
            {generalChargeOptions.map((coin, i) => (
              <Flex
                key={i}
                align="center"
                justify="space-between"
                style={{
                  padding: "2.6rem 3rem",
                  border: `1px solid ${
                    coin.coin === selectedCost ? colors.red67 : colors.grey77
                  }`,
                  borderRadius: "0.8rem",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedCost(coin.coin)}
              >
                <Flex align="center" gap={1.3}>
                  <Icon
                    name={
                      selectedCost === coin.coin
                        ? "IconChargeCheck"
                        : "IconChargeUnCheck"
                    }
                    width="3.9rem"
                    height="3.9rem"
                  />

                  <Flex direction="column" gap={0.6}>
                    <Flex>
                      <Text typography="t24" color="main" fontWeight={500}>
                        {coin.coin} 코인
                      </Text>
                      {coin.plusCoin && (
                        <Text typography="t24" color="main" fontWeight={500}>
                          (+{coin.plusCoin}코인)
                        </Text>
                      )}
                    </Flex>
                    {coin.percent !== 0 && (
                      <Flex
                        style={{
                          paddingLeft: "1.9rem",
                        }}
                      >
                        <Text typography="t14" color="red67" fontWeight={700}>
                          + {coin.percent}% 코인 보너스
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <Text typography="t24" color="black33" fontWeight={400}>
                  ₩ {coin.coin}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Division height="0.8rem" color="grey94" />
        {/*  결제 수단 */}
        <Flex direction="column">
          <Text
            typography="t20"
            color="main"
            fontWeight={700}
            style={{
              padding: "2.6rem 3.4rem 1.8rem",
            }}
          >
            결제수단
          </Text>
          <Flex
            direction="column"
            gap={0.8}
            style={{
              padding: "2.3rem 3.4rem 2.6rem",
            }}
          >
            <Flex gap={0.8} align="center">
              <Circle width="2rem" height="2rem" color="red67" />
              <Text typography="t20" color="grey53" fontWeight={400}>
                만월페이
              </Text>
            </Flex>

            <Flex
              direction="column"
              gap={1.6}
              justify="center"
              align="center"
              style={{
                background: colors.ivory95,
                border: `1px solid ${colors.grey85}`,
                position: "relative",
                width: "45rem",
                height: "25rem",
                margin: "0 auto",
                borderRadius: "1rem",
                boxSizing: "border-box",
              }}
            >
              <Icon name="IconManwolPay" />
              <Text typography="t20" color="grey53" fontWeight={400}>
                10초 카드 등록!
              </Text>
            </Flex>
          </Flex>
          <Division height="0.8rem" color="grey94" />
          <Flex
            direction="column"
            gap={1.8}
            style={{
              padding: "2.3rem 3.4rem 2.6rem",
              //   background: "skyblue",
            }}
          >
            <Flex gap={0.8} align="center">
              <Circle width="2rem" height="2rem" color="red67" />
              <Text typography="t20" color="grey53" fontWeight={400}>
                일반결제
              </Text>
            </Flex>

            <Button full color="pink" weak height="7rem" hover={false}>
              <Flex align="center" justify="center" gap={1.2}>
                <Icon name="IconCheckPink" width="1.8rem" height="1.8rem" />
                <Text typography="t24" color="red67" fontWeight={700}>
                  신용카드,간편결제
                </Text>
              </Flex>
            </Button>
          </Flex>
          <Division height="0.8rem" color="grey94" />

          <Flex
            direction="column"
            gap={1.8}
            style={{
              padding: "2.6rem 3.4rem 1.5rem",
            }}
          >
            <Text
              typography="t20"
              color="main"
              fontWeight={700}
              style={{
                padding: "2.6rem 3.4rem 1.8rem",
              }}
            >
              결제금액
            </Text>

            <Flex direction="column" gap={0.8}>
              <Flex align="center" justify="space-between">
                <Text typography="t20" color="grey53" fontWeight={400}>
                  상품금액
                </Text>
                <Text typography="t20" color="black33" fontWeight={400}>
                  {selectedCost}원
                </Text>
              </Flex>

              <Flex align="center" justify="space-between">
                <Text typography="t20" color="grey53" fontWeight={400}>
                  부가세(10%)
                </Text>
                <Text typography="t20" color="black33" fontWeight={400}>
                  {Number(selectedCost?.replace(/,/g, "")) * 0.1}원
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Division color="grey94" />

          <Flex
            direction="column"
            gap={1.8}
            style={{
              padding: "2.6rem 3.4rem 1.5rem",
            }}
          >
            <Flex align="center" justify="space-between">
              <Text typography="t20" color="main" fontWeight={400}>
                총 결제금액
              </Text>
              <Text typography="t24" color="black33" fontWeight={400}>
                55,000원
              </Text>
            </Flex>
            <Button
              color="pink"
              height="7rem"
              full
              typography="t24"
              fontWeight={700}
              hover={false}
            >
              결제하기
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </ChargeContainer>
  );
};

export default ChargeCoinPage;

const ChargeContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  padding: 4rem 0;
`;

const Tab = styled.div<{ isActive: boolean }>`
  flex: 1;
  padding: 1.3rem 0.7rem;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${colors.red67};
  font-weight: ${({ isActive }) => (isActive ? "700" : "400")};

  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? colors.red67 : "rgba(255, 86, 86, 0.2)")};

  cursor: pointer;
  transition: all 0.3s ease;
`;

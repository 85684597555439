import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";

import { NO_WHITESPACE_PATTERN, PASSWORD_PATTERN } from "@constants/regExp";

import * as S from "./ResetPwLayout.style";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import Flex from "@atoms/Flex/Flex";
import Label from "@atoms/Label/Label";
import Input from "@atoms/Input/Input";
import Button from "@atoms/Button/Button";
import Icon from "@atoms/Icon/Icon";
import ArrowStyledIcon from "@atoms/Icon/ArrowStyledIcon";

interface ResetProps {
  password: string;
}

const ResetPwLayout = () => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  const onSubmit = (data: ResetProps & { passwordCheck: string }) => {
    console.log(data);
    navigate("/login");
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ResetProps & { passwordCheck: string }>();

  const noError = Object.keys(errors).length === 0;

  const password = watch("password");
  return (
    <S.ResetPwContainer onSubmit={handleSubmit(onSubmit)}>
      <Text typography="t24" fontWeight={600} color="main">
        비밀번호 재설정
      </Text>
      <ArrowStyledIcon onClick={() => navigate(-1)} />

      <Spacer space="10.4rem" />

      <Flex direction="column" full>
        <Text typography="t30" color="main">
          새로운 비밀번호를 입력해주세요
        </Text>
        <Spacer space="2rem" />
        <Text
          typography="t16"
          color="grey53"
          style={{
            paddingLeft: "0.8rem",
          }}
        >
          비밀번호를 6자 이상(영문,숫자,특수문자 포함) 입력해 주시기 바랍니다.
        </Text>
      </Flex>

      <Spacer space="3.5rem" />

      <Flex full direction="column">
        <Label typography="t20" htmlFor="password" color="black33">
          새로운 비밀번호
        </Label>
        <Spacer space="1.8rem" />
        <Flex
          style={{
            position: "relative",
          }}
        >
          <Input
            id="password"
            placeholder="새로운 비밀번호"
            type={visible ? "text" : "password"}
            typography="t20"
            placeholderFontSize="t20"
            autoComplete="off"
            padding="0px 2rem"
            borderRadius="1.5rem"
            {...register("password", {
              required: "비밀번호를 입력해주세요.",
              validate: {
                noWhitespace: (value) =>
                  NO_WHITESPACE_PATTERN.test(value) ||
                  "공백은 사용할 수 없습니다.",
                isValidPattern: (value) =>
                  NO_WHITESPACE_PATTERN.test(value)
                    ? PASSWORD_PATTERN.test(value) ||
                      "비밀번호는 6자 이상(영문, 숫자, 특수문자 포함)이어야 합니다."
                    : true,
              },
            })}
          />

          <Icon
            name="IconInVible"
            onClick={toggleVisible}
            width="2.4rem"
            height="2.4rem"
            style={{
              position: "absolute",
              right: "1.5rem",
              top: "50%",
              cursor: "pointer",
              transform: "translateY(-50%)",
            }}
          />
        </Flex>
        <Spacer space="1rem" />
        {errors?.password && (
          <Text typography="t18" fontWeight={200} color="red67">
            {errors?.password.message}
          </Text>
        )}
      </Flex>

      <Spacer space="3rem" />

      <Flex full direction="column">
        <Label typography="t20" htmlFor="passwordCheck" color="black27">
          새로운 비밀번호 확인
        </Label>
        <Spacer space="1.8rem" />
        <Flex
          style={{
            position: "relative",
          }}
        >
          <Input
            id="passwordCheck"
            placeholder="새로운 비밀번호 확인"
            type={visible ? "text" : "password"}
            placeholderFontSize="t20"
            autoComplete="off"
            padding="0px 2rem"
            borderRadius="1.5rem"
            {...register("passwordCheck", {
              required: "비밀번호를 먼저 입력해주세요.",
              validate: {
                noWhitespace: (value) =>
                  NO_WHITESPACE_PATTERN.test(value) ||
                  "공백은 사용할 수 없습니다.",
                matchesPassword: (value) =>
                  value === password || "비밀번호가 일치하지 않습니다.",
              },
            })}
          />

          <Icon
            name="IconInVible"
            onClick={toggleVisible}
            width="2.4rem"
            height="2.4rem"
            style={{
              position: "absolute",
              right: "1.5rem",
              top: "50%",
              cursor: "pointer",
              transform: "translateY(-50%)",
            }}
          />
        </Flex>

        <Spacer space="1rem" />
        {errors?.passwordCheck && (
          <Text typography="t18" fontWeight={200} color="red67">
            {errors?.passwordCheck?.message}
          </Text>
        )}
      </Flex>

      <Spacer space="19.2rem" />

      <Button
        type="submit"
        // onClick={() => navigate("/login")}
        onClick={() => {}}
        color="next"
        width="100%"
        height="7rem"
        typography="t24"
        disabled={!noError}
        hover={false}
      >
        비밀번호 변경하기
      </Button>
    </S.ResetPwContainer>
  );
};

export default ResetPwLayout;

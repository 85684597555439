import styled from "@emotion/styled";
import Icon from "@atoms/Icon/Icon";

export const StyledIcon = styled(Icon)`
  position: absolute;
  left: 2.8rem;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.08);
  }
`;

import Button from "@/components/atoms/Button/Button";
import Flex from "@/components/atoms/Flex/Flex";
import Icon from "@/components/atoms/Icon/Icon";
import Text from "@/components/atoms/Text/Text";
import { usePopup } from "@/hooks/usePopup";
import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";

interface AlertPopupProps {
  desc: string;
  clickBtn: () => void;
}

const AlertPopup = ({ desc, clickBtn }: AlertPopupProps) => {
  const { closePopup } = usePopup();
  return (
    <AlertPopupContainer>
      <Flex
        justify="center"
        align="center"
        style={{
          position: "relative",
          padding: "1.6rem 0",
          borderBottom: `1px solid ${colors.grey85}`,
        }}
      >
        <Text typography="t20" color="black" fontWeight={600}>
          알림
        </Text>
        <Icon
          name="IconModalClose"
          width="2.8rem"
          height="2.8rem"
          style={{
            position: "absolute",
            top: "50%",
            right: "1.6rem",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={closePopup}
        />
      </Flex>

      <Flex
        justify="center"
        align="center"
        style={{
          height: "12.8rem",
        }}
      >
        <Text typography="t20" color="black33" fontWeight={400}>
          {desc}
        </Text>
      </Flex>

      <Button
        color="pink"
        width="100%"
        height="6rem"
        borderRadius="0px"
        hover={false}
        onClick={clickBtn}
      >
        앱 설치하기
      </Button>
    </AlertPopupContainer>
  );
};

export default AlertPopup;

const AlertPopupContainer = styled.div`
  width: 40rem;
  height: 24.7rem;
  border-radius: 1.2rem;
  background-color: ${colors.white100};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
`;

import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "@constants/breakpoint";

export const ApplyContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2.4rem 3rem 5rem 3rem;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MEDIA_QUERIES.MOBILE} {
    max-width: 300px;
  }
  @media ${MEDIA_QUERIES.TABLET} {
    max-width: 400px;
  }
  @media ${MEDIA_QUERIES.LAPTOP} {
    max-width: 500px;
  }
`;

import { ColorsType } from "@/style/colorPalette";
import * as S from "./Division.style";

export interface DivisionProps {
  width?: string;
  height?: string;
  color?: ColorsType;
}

const Division = ({ width, height, color }: DivisionProps) => {
  return <S.Division width={width} height={height} color={color} />;
};

export default Division;

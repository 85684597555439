import styled from "@emotion/styled";

export const TeacherDetailLayout = styled.main`
  /* max-width: 1121px; */
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import { colors } from "@/style/colorPalette";
import styled from "@emotion/styled";
import { CircleProps } from "./Circle";

export const Circle = styled.div<CircleProps>`
  border-radius: 50%;
  width: ${(props) => props.width || "0.9rem"};
  height: ${(props) => props.height || "0.9rem"};
  background-color: ${(props) =>
    props.color ? colors[props.color] : colors.white100};
  border: 3px solid ${colors.grey86};
  box-sizing: border-box;
`;

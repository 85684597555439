import { css } from "@emotion/react";

import { colors } from "@style/colorPalette";

export const buttonColorMap = {
  primary: css`
    background-color: ${colors.main};
    color: ${colors.white100};
  `,
  success: css`
    background-color: ${colors.green49};
    color: ${colors.white100};
  `,
  error: css`
    background-color: ${colors.red50};
    color: ${colors.white100};
  `,
  cancel: css`
    background-color: ${colors.main};
    color: ${colors.ivory97};
  `,
  next: css`
    background-color: ${colors.red67};
    color: ${colors.white100};
  `,
  grey: css`
    background-color: ${colors.grey86};
    color: ${colors.white100};
  `,
  pink: css`
    background-color: ${colors.red67};
    color: ${colors.white100};
  `,
};

export const buttonWeakMap = {
  primary: css`
    background-color: ${colors.white100};
    color: ${colors.main};
    border: 1px solid ${colors.main};
  `,
  success: css`
    background-color: ${colors.white100};
    color: ${colors.green49};
    border: 1px solid ${colors.green49};
  `,
  error: css`
    background-color: ${colors.white100};
    color: ${colors.red50};
    border: 1px solid ${colors.red50};
  `,
  cancel: css`
    background-color: ${colors.ivory97};
    color: ${colors.main};
    border: 1px solid ${colors.grey77};
  `,
  next: css`
    background-color: ${colors.white100};
    color: ${colors.red67};
    border: 1px solid ${colors.red67};
  `,
  grey: css`
    background-color: ${colors.ivory97};
    color: ${colors.grey86};
    border: 1px solid ${colors.grey86};
  `,
  pink: css`
    background-color: ${colors.white100};
    color: ${colors.red67};
    border: 1px solid ${colors.red67};
  `,
};

export type ButtonColorType = keyof typeof buttonColorMap;

import * as S from "./TeacherSimpleCard.style";
import { TeacherCardProps } from "@models";
import Flex from "@atoms/Flex/Flex";
import Image from "@atoms/Image/Image";
import Spacer from "@atoms/Spacer/Spacer";
import Text from "@atoms/Text/Text";
import { returnColor } from "@utils";
import { useNavigate } from "react-router-dom";

const TeacherSimpleCard = ({ teacher }: { teacher: TeacherCardProps }) => {
  const navigate = useNavigate();
  return (
    <S.TeacherSimpleCardContainer
      onClick={() => navigate(`/teacher/${teacher.id}`)}
    >
      <Flex direction="column" align="center">
        <Image
          src={teacher.img}
          alt={`${teacher.id} 선생님 이미지`}
          width="100%"
          height="110px"
          borderRadius="10px"
        />
        <Spacer space="1.3rem" />
        <Text typography="t20">{teacher.name}</Text>
        <Spacer space="0.4rem" />
        <Text typography="t16" color={returnColor(teacher.kind)}>
          #{teacher.kind}
        </Text>
      </Flex>
    </S.TeacherSimpleCardContainer>
  );
};

export default TeacherSimpleCard;

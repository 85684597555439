import styled from "@emotion/styled";
import { colors, ColorsType } from "@style/colorPalette";

export const Division = styled.div<{
  width?: string;
  height?: string;
  color?: ColorsType;
}>`
  width: ${({ width }) => width ?? "100%"};
  height: ${({ height }) => height ?? "1px"};
  background-color: ${({ color }) => (color ? colors[color] : colors.grey85)};
`;

import { useNavigate, useParams } from "react-router-dom";

import Image from "@atoms/Image/Image";
import * as S from "./TeacherDetailSection.style";
import tarot from "@img/tarot.jpg";
import Text from "@/components/atoms/Text/Text";
import Flex from "@/components/atoms/Flex/Flex";
import CoinGroup from "@/components/molecules/Group/CoinGroup";
import { TeacherCardProps } from "@/models";
import Spacer from "@/components/atoms/Spacer/Spacer";
import Icon from "@/components/atoms/Icon/Icon";
import { colors } from "@/style/colorPalette";
import CategoryBox from "@/components/molecules/Category/CategoryBox";
import Division from "@/components/atoms/Division/Division";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import Img from "@img/detail_img.png";
import Button from "@/components/atoms/Button/Button";
import SearchDropDown from "@/components/molecules/DropDown/SearchDropDown";
import { usePopup } from "@/hooks/usePopup";
import Popup from "@/components/atoms/Popup/Popup";
import AlertPopup from "@/components/molecules/Popup/AlertPopup";

function TeacherDetailSection() {
  const { teacherId } = useParams();

  const navigate = useNavigate();
  const teacher: TeacherCardProps = {
    id: 1,
    name: "심채운0",
    status: "상담중",
    rating: "4.2",
    price: "1,500",
    tags: ["연애", "이별", "속마음", "연애", "이별"],
    isNew: true,
    categories: ["#단호박 공수", "#직설적인", "#갓신내림", "#단호박 공수"],
    img: "https://images.unsplash.com/photo-1731100062072-3904a79ff817?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDR8TThqVmJMYlRSd3N8fGVufDB8fHx8fA%3D%3D",
    kind: "신점",
    like: false,
  };

  const reviewArray = [
    {
      star: 5,
      title:
        "선생님~안녕하세요.이별당시 했던 말들을 똑같이 말씀하실때 말씀하실때 말씀하실 때",
      description:
        "선생님~안녕하세요 선생님과의 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게 들을 수 있는 시간이었습니다. 선생님의 가장 뛰어나신 부분은 성향을 정확하게 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    },
    {
      star: 3,
      title:
        "선생님~안녕하세요.이별당시 했던 말들을 똑같이 말씀하실때 말씀하실때 말씀하실 때",
      description:
        "선생님~안녕하세요 선생님과의 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게 들을 수 있는 시간이었습니다. 선생님의 가장 뛰어나신 부분은 성향을 정확하게 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    },
    {
      star: 3,
      title:
        "선생님~안녕하세요.이별당시 했던 말들을 똑같이 말씀하실때 말씀하실때 말씀하실 때",
      description:
        "선생님~안녕하세요 선생님과의 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게 들을 수 있는 시간이었습니다. 선생님의 가장 뛰어나신 부분은 성향을 정확하게 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    },
    {
      star: 4,
      title:
        "선생님~안녕하세요.이별당시 했던 말들을 똑같이 말씀하실때 말씀하실때 말씀하실 때",
      description:
        "선생님~안녕하세요 선생님과의 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게 들을 수 있는 시간이었습니다. 선생님의 가장 뛰어나신 부분은 성향을 정확하게 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    },
    {
      star: 2,
      title:
        "선생님~안녕하세요.이별당시 했던 말들을 똑같이 말씀하실때 말씀하실때 말씀하실 때",
      description:
        "선생님~안녕하세요 선생님과의 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게 들을 수 있는 시간이었습니다. 선생님의 가장 뛰어나신 부분은 성향을 정확하게 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 것 같았어요 정말 최고완전최고완전최고완전최고완전최고완전\n 아 진짜 정말",
    },
  ];

  const [isExpanded, setIsExpanded] = useState(false);

  const text = `타로상담사 1급 보유 \n 타로상담사 전문가과정수료\n 🚩4년차 신애제자\n늘 기도하며 꿰뚫어 보는 화경을 보며 내려주신 영적인 영검으로 정확하고 옳고 바른 신점 \n🚩통신으로도 지기를 받으며 건강도 케어`;
  const lines = text.split("\n");
  const isTruncated = lines.length >= 3;
  const visibleLines = isExpanded ? lines : lines.slice(0, 3);

  const notiText = `🚩11월29일(금)\n\n타로수업 및 강의 개인상담 예약 외,\n 지금부터 23시 반까지 수시대기합니다.\n\n부재시,\n접속알림신청 눌러두시면 제가\n켤 때 오픈 메세지 갈거니 참고하세요 ^^*\n\n급한 상담은, <상담예약> 주시면\n달려올거니 운세7<고객센터>로 문의! `;

  const reviewText = `선생님~안녕하세요. 선생님과의 첫번째상담이었어용!\n 상대방 속마음과 생각들을 너무 정확하게 들을 수 있는 시간이었습니다.\n선생님의 가장 뛰어나신 부분은 성향을 정확하게 파악하시고 그거에 맞는 답변을 콕 찝어서 해주시는 뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기뭐시기`;

  const answerText = `여리여니님~🩷🩵\n진심을 꾹꾹 눌러담은 소중한 댓글 넘나 제가 더ㅓㅓㅓㅓ 감동‼️저와의 상담을 통해 위안이 되시고 \n속이 후련해 지셨다니 너무 다행이예요🍀 앞으로 여리여니님 오실때마다 인생에 현명하고\n 더 좋은`;

  const filterArray = ["추천순", "최신순", "별점순"];

  const [activeFilter, setActiveFilter] = useState("추천순");
  const [openDropDown, setOpenDropDown] = useState(false);

  const clickActive = (filter: string) => {
    setActiveFilter(filter);
    setOpenDropDown(false);
  };

  const homeRef = useRef<HTMLDivElement>(null);
  const notiRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);

  const tabs = ["홈", "공지", "소개", "후기"];
  const [activeTab, setActiveTab] = useState("홈");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);

    const headerHeight = 70;
    // 섹션별 ref로 스크롤 이동
    const sectionMap: { [key: string]: React.RefObject<HTMLDivElement> } = {
      홈: homeRef,
      공지: notiRef,
      소개: introRef,
      후기: reviewRef,
    };

    const targetRef = sectionMap[tab];
    if (targetRef.current) {
      const offsetTop = targetRef.current.offsetTop - headerHeight;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const { activePopup, closePopup } = usePopup();

  // 상담 알람 신청
  // const applyAlarm = () => {};

  return (
    <S.TeacherDetailSection>
      <Flex
        justify="space-between"
        style={{
          background: colors.ivory97,
          position: "sticky",
          top: 0,
          padding: "0 0.5rem",
          zIndex: 2,
          height: "5.5rem",
        }}
      >
        <Icon
          name="IconClose"
          onClick={() => navigate(-1)}
          width="3.7rem"
          height="3.7rem"
        />
        <Icon name="IconShare" width="3.8rem" height="3.8rem" />
      </Flex>
      <Flex
        justify="center"
        style={{
          boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
          borderRadius: "1rem",
          width: "98%",
          margin: "0 auto",
        }}
      >
        <Image
          src={tarot}
          alt={`${teacherId}-1`}
          width="100%"
          height="50rem"
          borderRadius="1rem"
          objectFit="cover"
        />
      </Flex>

      <Spacer space="1.5rem" />

      <Flex
        gap={1.1}
        direction="column"
        style={{
          padding: "0 0.5rem",
          boxSizing: "border-box",
        }}
      >
        <Flex align="center" gap={1}>
          <Text typography="t28" color="main" fontWeight={600}>
            {teacher.name}
          </Text>
          <Text typography="t20" color="grey53">
            {teacher.id}번
          </Text>
        </Flex>
        <CoinGroup teacher={teacher} size="large" justify="flex-start" />
        <Flex
          gap={0.5}
          style={{
            flexWrap: "wrap",
          }}
        >
          {teacher.tags.map((tag, i) => (
            <CategoryBox key={i} size="large">
              <Text typography="t16" color="black33">
                {tag}
              </Text>
            </CategoryBox>
          ))}
        </Flex>
        <Flex gap={1}>
          {teacher.categories.map((category, i) => (
            <Text typography="t20" color="black33" key={i} fontWeight={400}>
              {category}
            </Text>
          ))}
        </Flex>
      </Flex>

      <Spacer space="2.4rem" />
      <Division />

      <Flex
        align="center"
        gap={1.2}
        style={{
          padding: "1.2rem 0.5rem",
          boxSizing: "border-box",
        }}
      >
        <Flex gap={0.6} align="center">
          <Icon name="IconEvent" width="2.4rem" height="2.4rem" />
          <Text typography="t20" fontWeight={600} color="main">
            이벤트
          </Text>
        </Flex>

        <Text typography="t20" fontWeight={400} color="black33">
          후기 작성시 최대{" "}
          <Text typography="t20" fontWeight={400} color="red67">
            2,000
          </Text>{" "}
          포인트 지급
        </Text>
      </Flex>

      <Division height="0.8rem" />

      <Flex
        direction="column"
        gap={1.4}
        style={{
          padding: "1.4rem 0.5rem",
          boxSizing: "border-box",
        }}
      >
        <Text typography="t24" color="main" fontWeight={600}>
          상담후기
        </Text>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={17}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {reviewArray.map((review, i) => (
            <SwiperSlide
              key={i}
              style={{
                width: "55rem",
              }}
            >
              {/* <BestReviewCard review={review} /> */}
              <ReviewCard>
                <Flex>
                  {Array.from({ length: review.star }).map((_, idx) => (
                    <Icon key={idx} name="IconReviewStar" />
                  ))}
                </Flex>
                <Flex gap={0.7} direction="column">
                  <Text
                    typography="t20"
                    color="black33"
                    fontWeight={600}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {review.title}
                  </Text>

                  <Text
                    typography="t20"
                    color="black33"
                    fontWeight={300}
                    style={{
                      whiteSpace: "pre-line",
                      height: "7.7rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      lineHeight: "130%",
                    }}
                  >
                    {review.description}
                  </Text>
                </Flex>
              </ReviewCard>
            </SwiperSlide>
          ))}
        </Swiper>

        <Division height="0.8rem" />
      </Flex>
      {/* 탭 */}
      <Flex
        style={{
          borderBottom: `1px solid ${colors.grey94}`,
          position: "sticky",
          top: "3.8rem",
          zIndex: 2,
          background: colors.ivory97,
        }}
      >
        {tabs.map((tab) => {
          const isActive = activeTab === tab;
          return (
            <TabItem onClick={() => handleTabClick(tab)} key={tab}>
              <Text
                typography="t20"
                color={isActive ? "black33" : "grey53"}
                fontWeight={isActive ? 700 : 300}
              >
                {tab}
              </Text>
              {isActive && <UnderLine isActive={isActive} />}
            </TabItem>
          );
        })}
      </Flex>

      <HomeSection ref={homeRef}>
        {/* 선생님 정보 */}
        <Flex gap={1} align="center">
          <Image
            src={teacher.img}
            alt={`${teacherId}선생님`}
            width="9rem"
            height="9rem"
            borderRadius="50%"
            objectFit="cover"
          />
          <Flex direction="column" gap={0.5}>
            <Flex align="center" gap={0.8}>
              {/* kind 뱃지 */}
              <Flex
                align="center"
                justify="center"
                style={{
                  background: colors.red95,
                  padding: "0.3rem 0.8rem",
                  borderRadius: "0.5rem",
                }}
              >
                <Text typography="t20" color="red50" fontWeight={500}>
                  {teacher.kind}
                </Text>
              </Flex>
              <Text typography="t20" color="grey53" fontWeight={500}>
                {teacherId}번
              </Text>
            </Flex>

            <Text typography="t28" color="main" fontWeight={600}>
              {teacher.name}
            </Text>
          </Flex>
        </Flex>

        <Spacer space="1.4rem" />

        {/* 상담 시간 */}
        <Flex gap={1} align="start">
          <Flex gap={0.7} align="center">
            <Icon name="IconTime" width="2.4rem" height="2.4rem" />
            <Text typography="t20" color="main" fontWeight={600}>
              상담시간
            </Text>
          </Flex>
          <Flex direction="column" gap={0.7}>
            <Text
              typography="t20"
              color="main"
              fontWeight={300}
              style={{
                whiteSpace: "pre-line",
                lineHeight: 1.2,
              }}
            >
              {` 월-금 13:00~18:00 / 23:00~05:00 \n 매주 토,일요일/법정 공휴일 휴무`}
            </Text>
          </Flex>
        </Flex>

        <Spacer space="1.8rem" />
        {/* 상담 방법 */}
        <Flex gap={1} align="center">
          <Flex align="center" gap={0.7}>
            <Icon name="IconMic" width="2.4rem" height="2.4rem" />
            <Text typography="t20" color="main" fontWeight={600}>
              상담방법
            </Text>
          </Flex>

          <Text typography="t20" color="main" fontWeight={300}>
            전화, 채팅
          </Text>
        </Flex>
        <Spacer space="1.8rem" />

        {/* 상담사 이력 */}
        <Flex gap={0.7} align="start">
          <Icon name="IconHistory" width="2.4rem" height="2.4rem" />
          <Flex
            direction="column"
            style={{
              position: "relative",
            }}
          >
            <Text
              typography="t20"
              color="main"
              fontWeight={300}
              style={{
                whiteSpace: "pre-line",
                lineHeight: 1.6,
                maxWidth: "40rem",
              }}
            >
              {visibleLines.join("\n")}
            </Text>
            {isTruncated && (
              <Icon
                onClick={() => setIsExpanded((prev) => !prev)}
                name={isExpanded ? "IconUp" : "IconDown"}
                width="2.7rem"
                height="2.7rem"
                style={{
                  position: "absolute",
                  right: "5px",
                  bottom: "5px",
                  cursor: "pointer",
                }}
              />
            )}
          </Flex>
        </Flex>
      </HomeSection>
      <Division height="0.8rem" />
      <NotiSection ref={notiRef}>
        <Flex direction="column">
          <Text typography="t24" color="main" fontWeight={600}>
            공지
          </Text>
          <Spacer space="1.6rem" />
          <Text
            typography="t20"
            color="black33"
            fontWeight={300}
            style={{
              whiteSpace: "pre-line",
              lineHeight: 1.6,
              maxWidth: "40rem",
            }}
          >
            {notiText}
          </Text>
        </Flex>
      </NotiSection>
      <Division height="0.8rem" />
      <IntroSection ref={introRef}>
        <Flex direction="column" gap={0.7}>
          <Text typography="t24" color="main" fontWeight={600}>
            소개
          </Text>
          <Flex
            justify="center"
            align="center"
            direction="column"
            style={{
              maxWidth: "53.8rem",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Icon name="IconDetailMoon" width="2.4rem" height="2.4rem" />
            <Spacer space="1.4rem" />
            <Line />
            <Spacer space="1.4rem" />
            <Flex
              style={{
                position: "relative",
                height: "36rem",
              }}
            >
              <img
                src={Img}
                alt="img"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1))",
                  pointerEvents: "none",
                }}
              />
            </Flex>
            <Spacer space="3.1rem" />
            <Text
              typography="t28"
              color="main"
              fontWeight={600}
              style={{
                width: "100%",
              }}
            >
              어떤 사연이든 제가 안내해드리겠습니다.
            </Text>
            <Spacer space="1.4rem" />

            <Text
              typography="t24"
              color="main"
              fontWeight={300}
              style={{
                whiteSpace: "pre-line",
                lineHeight: 1.6,
              }}
            >
              어떤 사연이든 제가 안내해드리겠습니다. 시가과 공간의 제약을 받지
              않으니까요. 내담자님이 좀 더 편하고 부담 없이 상담받을 수 있도록
              여유를 마련해 드립니다. 생년월일과 사연을 함께 말씀해주시면,
              이야기를 풀어가면서 고민을 하나씩 전부 들어드립니다. 상담 주제는
              가리지 않으며, 최선을 다합니다.
            </Text>
          </Flex>
          <Button
            color="grey"
            weak
            width="70.8rem"
            height="6.4rem"
            borderRadius="1rem"
            onClick={() => {}}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.7rem",
              margin: "0 auto",
            }}
            hover={false}
          >
            <Text typography="t22" color="black33" fontWeight={300}>
              상담마스터 소개 전체보기
            </Text>
            <Icon name="IconDown" width="3.3rem" height="3.3rem" />
          </Button>
        </Flex>
      </IntroSection>
      <Division height="0.8rem" />

      <ReviewSection ref={reviewRef}>
        <Flex direction="column">
          <Text typography="t24" color="main" fontWeight={600}>
            후기
          </Text>
          <Spacer space="1.9rem" />
          <Flex
            align="center"
            justify="space-between"
            style={{
              paddingLeft: "1.8rem",
              paddingBottom: "1.2rem",
            }}
          >
            <Flex gap={2.9}>
              <Flex gap={0.4} align="center">
                <Text typography="t20" color="main">
                  후기
                </Text>
                <Text typography="t20" color="grey53">
                  122개
                </Text>
              </Flex>
              <Flex align="center">
                <Icon
                  name="IconReviewStar"
                  width="1.8rem"
                  height="1.8rem"
                  style={{
                    marginTop: "-3px",
                  }}
                />
                <Text typography="t18" color="main" fontWeight={600}>
                  4.8
                </Text>
              </Flex>
            </Flex>

            <Flex
              align="center"
              style={{
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => setOpenDropDown(!openDropDown)}
            >
              <Text typography="t16" color="grey56">
                {activeFilter}
              </Text>
              <Icon name="IconDownGrey" width="2rem" height="2rem" />
              {openDropDown && (
                <SearchDropDown
                  data={filterArray}
                  clickActive={clickActive}
                  activeFilter={activeFilter}
                />
              )}
            </Flex>
          </Flex>
          <Division color="grey94" />
        </Flex>
        <Flex
          direction="column"
          style={{
            padding: "1.8rem 1.8rem 3.5rem 1.8rem",
          }}
        >
          <Flex align="center" gap={1}>
            <Image
              src={Img}
              alt=""
              width="5.5rem"
              height="5.5rem"
              borderRadius="50%"
            />
            <Flex direction="column" gap={0.8} justify="center">
              <Flex gap={0.2}>
                <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
                <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
                <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
                <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
                <Icon name="IconReviewStar" width="1.86rem" height="1.7rem" />
              </Flex>
              <Flex gap={0.4} align="center">
                <Text typography="t16">따뜻한 양한마리</Text>
                <CategoryBox>
                  <Text typography="t16" color="grey53">
                    전화상담
                  </Text>
                </CategoryBox>

                <Text typography="t16" color="grey53">
                  24.10.10
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Spacer space="2.5rem" />
          <Text typography="t20" color="main" fontWeight={600}>
            선생님~감사합니다
          </Text>
          <Spacer space="1.7rem" />
          <Text
            typography="t16"
            color="black33"
            fontWeight={300}
            style={{
              // maxWidth: "75.5rem",
              whiteSpace: "pre-line",
              overflow: "hidden",
              lineHeight: "160%",
              textOverflow: "ellipsis",
              display: "-webkit-box", // 여러 줄 말줄임표를 위해 추가
              WebkitLineClamp: 3, // 최대 줄 수 설정  --> 이건 -webkit-만 가능 -o-, -moz-는 안됨(직접 구현 필요)
              WebkitBoxOrient: "vertical", // 세로 방향 클램프 설정
            }}
          >
            {reviewText}
          </Text>
          <Spacer space="1.6rem" />
          <Flex justify="space-between" align="center">
            <CategoryBox size="large">
              <Text typography="t16" color="black33">
                이별
              </Text>
            </CategoryBox>
            <Flex gap={1.5} align="center">
              <Text typography="t16" color="grey86" fontWeight={400}>
                리뷰가 도움이 되었다면
              </Text>

              <Button
                width="6.4rem"
                height="3.7rem"
                borderRadius="2rem"
                color="grey"
                weak
                onClick={() => {}}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon name="IconThumbUp" width="2.4rem" height="2.4rem" />
                <Text typography="t16" fontWeight={300} color="black">
                  1
                </Text>
              </Button>
            </Flex>
          </Flex>
          <Spacer space="1.54rem" />
          <Flex gap={1.1} align="start">
            <Image
              src={teacher.img}
              alt="선생님 이미지"
              width="4.5rem"
              height="4.5rem"
              borderRadius="50%"
              style={{
                flexShrink: 0,
              }}
            />
            <Flex
              direction="column"
              full
              style={{
                padding: "1.4rem 3rem 1.4rem 1.9rem",
                background: colors.ivory95,
                borderRadius: "0.7rem",
              }}
            >
              <Flex align="center" gap={0.4}>
                <Text typography="t20" color="black" fontWeight={600}>
                  {teacher.name}
                </Text>
                <Text typography="t16" color="grey53" fontWeight={500}>
                  2일 전
                </Text>
              </Flex>
              <Spacer space="0.8rem" />
              <Text
                typography="t16"
                color="black33"
                fontWeight={300}
                style={{
                  // maxWidth: "75.5rem",
                  whiteSpace: "pre-line",
                  overflow: "hidden",
                  lineHeight: "160%",
                  textOverflow: "ellipsis",
                  display: "-webkit-box", // 여러 줄 말줄임표를 위해 추가
                  WebkitLineClamp: 3, // 최대 줄 수 설정  --> 이건 -webkit-만 가능 -o-, -moz-는 안됨(직접 구현 필요)
                  WebkitBoxOrient: "vertical", // 세로 방향 클램프 설정
                }}
              >
                {answerText}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Division color="grey94" />
        <Spacer space="1.1rem" />

        <Division height="0.8rem" />
      </ReviewSection>

      <Flex
        direction="column"
        align="center"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          background: colors.ivory97,
          zIndex: 10,
        }}
      >
        <Spacer space="1.4rem" />
        {/* 상담중 */}
        {/* <Flex
          gap={1.3}
          style={{
            position: "relative",
            paddingBottom: "2rem",
          }}
        >
          <Flex direction="column" align="center">
            <Icon name="IconChat" width="4rem" height="4rem" />
            <Text typography="t14" color="main">
              1:1문의
            </Text>
          </Flex>
          <Button
            width="68.9rem"
            height="5.5rem"
            borderRadius="0.7rem"
            color="primary"
            typography="t20"
            hover={false}
            fontWeight={600}
            style={{
              cursor: "default",
            }}
          >
            상담중
          </Button>
          <Flex
            align="center"
            style={{
              position: "absolute",
              right: "3.5rem",
              top: "31%",
              transform: "translateY(-31%)",
              cursor: "pointer",
            }}
            onClick={() => openPopup("apply")}
          >
            <Text typography="t12" color="white100">
              상담알림 신청
            </Text>
            <Icon name="IconBell" width="2.5rem" height="2.5rem" />
          </Flex>
        </Flex> */}

        {/* 상담하기 */}
        <Flex
          gap={1.3}
          style={{
            position: "relative",
            paddingBottom: "2rem",
          }}
        >
          <Button
            onClick={() => {
              navigate(`/teacher/${teacherId}/counsel`);
            }}
            width="68.9rem"
            height="5.5rem"
            borderRadius="0.7rem"
            color="primary"
            typography="t20"
            hover={false}
            fontWeight={600}
            weak
          >
            상담하기
          </Button>
        </Flex>

        {/* 부재중 */}
        {/* <Flex
          gap={1.3}
          style={{
            position: "relative",
            paddingBottom: "2rem",
          }}
        >
          <Button
            onClick={() => {}}
            width="68.9rem"
            height="5.5rem"
            borderRadius="0.7rem"
            color="grey"
            typography="t20"
            hover={false}
            fontWeight={600}
          >
            부재중
          </Button>
          <Flex
            align="center"
            style={{
              position: "absolute",
              right: "3.5rem",
              top: "31%",
              transform: "translateY(-31%)",
            }}
          >
            <Text typography="t12" color="white100">
              상담알림 신청
            </Text>
            <Icon name="IconBell" width="2.5rem" height="2.5rem" />
          </Flex>
        </Flex> */}
      </Flex>

      <Flex
        direction="column"
        align="center"
        style={{
          borderRadius: "50%",
          background: colors.grey86,
          position: "fixed",
          right: "8rem",
          bottom: "8rem",
          width: "4rem",
          height: "4rem",
          cursor: "pointer",
          paddingTop: "0.2rem",
          boxSizing: "border-box",
          zIndex: 10,
        }}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <Icon name="IconTopArrow" width="1.8rem" height="1.8rem" />
        <Text typography="t12" color="white100" fontWeight={400}>
          TOP
        </Text>
      </Flex>

      {activePopup === "apply" && (
        <Popup togglePopup={closePopup}>
          <AlertPopup
            desc="상담알림신청은 앱에서만 가능합니다."
            clickBtn={closePopup}
          />
        </Popup>
      )}
    </S.TeacherDetailSection>
  );
}

export default TeacherDetailSection;

const ReviewCard = styled.div`
  padding: 1.3rem 1.8rem 1.4rem 2rem;
  border: 1px solid ${colors.grey94};
  border-radius: 1rem;
  box-sizing: border-box;
  width: 54.3rem;

  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  /* height: ; */
`;

const TabItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.7rem;
  position: relative;
  cursor: pointer;
`;

const UnderLine = styled.div<{ isActive: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  /* width: ${(props) => (props.isActive ? "100%" : "0")}; */
  width: 100%;
  height: 2px;
  background-color: ${colors.black33};
`;

const HomeSection = styled.section`
  padding: 1.9rem 0 2.4rem;
  box-sizing: border-box;
`;

const NotiSection = styled.section`
  padding: 1.9rem 0 2.4rem;
  box-sizing: border-box;
`;

const IntroSection = styled.section`
  padding: 1.9rem 0 2.4rem;
  box-sizing: border-box;
`;

const Line = styled.div`
  width: 1px;
  height: 7rem;
  background-color: ${colors.main};
`;

const ReviewSection = styled.section`
  padding: 1.9rem 0 4rem;
  box-sizing: border-box;
`;

import { useForm } from "react-hook-form";

import * as S from "./SignupForm.style";
import {
  EMAIL_PATTERN,
  NICKNAME_PATTERN,
  NO_WHITESPACE_PATTERN,
  PASSWORD_PATTERN,
} from "@constants/regExp";
import { SignupValue } from "@models";
import Text from "@atoms/Text/Text";
import Spacer from "@atoms/Spacer/Spacer";
import Flex from "@atoms/Flex/Flex";
import Label from "@atoms/Label/Label";
import { Input } from "@atoms/Input/Input.style";
import Division from "@atoms/Division/Division";
import Button from "@atoms/Button/Button";
import ArrowStyledIcon from "@atoms/Icon/ArrowStyledIcon";

const SignupForm = ({
  onSubmit,
  nextStep,
  prevStep,
}: {
  onSubmit: (formValue: SignupValue) => void;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignupValue & { passwordCheck: string }>();

  // const noError = Object.keys(errors).length === 0;

  const email = watch("email");
  const password = watch("password");
  const passwordCheck = watch("passwordCheck");
  const nickName = watch("nickName");

  // 버튼 비활성화 조건
  const isFormValid =
    !errors.email &&
    !errors.password &&
    !errors.passwordCheck &&
    !errors.nickName &&
    email &&
    password &&
    passwordCheck &&
    nickName;

  return (
    <S.SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Text typography="t24" fontWeight={600} color="main">
        회원가입
      </Text>
      {/* <S.StyledIcon name="IconBackArrow" onClick={prevStep} /> */}
      <ArrowStyledIcon onClick={prevStep} />
      <Spacer space="10.4rem" />
      <Flex direction="column" align="center" full>
        <Flex full direction="column">
          <Label typography="t30" htmlFor="email" color="main">
            이메일을 입력해주세요
          </Label>
          <Spacer space="1.8rem" />
          <Input
            id="email"
            placeholder="이메일 주소"
            placeholderFontSize="t20"
            autoComplete="off"
            padding="0px 2rem"
            borderRadius="1.5rem"
            {...register("email", {
              required: "이메일을 입력해주세요.",
              validate: {
                noWhitespace: (value) =>
                  NO_WHITESPACE_PATTERN.test(value) ||
                  "공백은 사용할 수 없습니다.",
                isValidPattern: (value) =>
                  NO_WHITESPACE_PATTERN.test(value)
                    ? EMAIL_PATTERN.test(value) ||
                      "올바른 이메일 형식이 아닙니다."
                    : true,
              },
            })}
          />
          <Spacer space="1rem" />
          {errors?.email && (
            <Text typography="t18" fontWeight={200} color="red67">
              {errors?.email.message}
            </Text>
          )}
        </Flex>

        <Spacer space="2.5rem" />

        <Flex full direction="column">
          <Label typography="t30" htmlFor="password" color="main">
            비밀번호를 입력해주세요
          </Label>
          <Spacer space="1.8rem" />
          <Input
            id="password"
            placeholder="비밀번호"
            type="password"
            placeholderFontSize="t20"
            autoComplete="off"
            padding="0px 2rem"
            borderRadius="1.5rem"
            {...register("password", {
              required: "비밀번호를 입력해주세요.",
              validate: {
                noWhitespace: (value) =>
                  NO_WHITESPACE_PATTERN.test(value) ||
                  "공백은 사용할 수 없습니다.",
                isValidPattern: (value) =>
                  NO_WHITESPACE_PATTERN.test(value)
                    ? PASSWORD_PATTERN.test(value) ||
                      "비밀번호는 6자 이상(영문, 숫자, 특수문자 포함)이어야 합니다."
                    : true,
              },
            })}
          />
          <Spacer space="1rem" />
          {errors?.password && (
            <Text typography="t18" fontWeight={200} color="red67">
              {errors?.password.message}
            </Text>
          )}

          <Spacer space="1rem" />
          <Input
            id="passwordCheck"
            placeholder="비밀번호 확인"
            type="password"
            placeholderFontSize="t20"
            autoComplete="off"
            padding="0px 2rem"
            borderRadius="1.5rem"
            {...register("passwordCheck", {
              required: "비밀번호를 먼저 입력해주세요.",
              validate: {
                noWhitespace: (value) =>
                  NO_WHITESPACE_PATTERN.test(value) ||
                  "공백은 사용할 수 없습니다.",
                matchesPassword: (value) =>
                  value === password || "비밀번호가 일치하지 않습니다.",
              },
            })}
          />
          <Spacer space="1rem" />
          {errors?.passwordCheck && (
            <Text typography="t18" fontWeight={200} color="red67">
              {errors?.passwordCheck?.message}
            </Text>
          )}
        </Flex>

        <Spacer space="5.2rem" />
        <Division width="100%" />
        <Spacer space="3rem" />

        <Flex full direction="column">
          <Label typography="t30" htmlFor="nickName" color="main">
            닉네임을 입력해주세요
          </Label>
          <Spacer space="1.8rem" />
          <Input
            id="nickName"
            placeholder="닉네임(2~10)"
            placeholderFontSize="t20"
            autoComplete="off"
            padding="0px 2rem"
            borderRadius="1.5rem"
            {...register("nickName", {
              required: "닉네임을 입력해주세요.",
              validate: {
                noWhitespace: (value) =>
                  NO_WHITESPACE_PATTERN.test(value) ||
                  "공백은 사용할 수 없습니다.",
                isValidPattern: (value) =>
                  NO_WHITESPACE_PATTERN.test(value)
                    ? NICKNAME_PATTERN.test(value) ||
                      "닉네임을 2~10자로 입력해 주시기 바랍니다."
                    : true,
              },
            })}
          />
          <Spacer space="1rem" />
          {errors?.nickName && (
            <Text typography="t18" fontWeight={200} color="red67">
              {errors?.nickName.message}
            </Text>
          )}
        </Flex>

        <Spacer space="5.1rem" />

        <Button
          type="submit"
          onClick={nextStep}
          color="next"
          width="100%"
          height="7rem"
          typography="t24"
          disabled={!isFormValid}
          hover={false}
        >
          다음
        </Button>
      </Flex>
    </S.SignupFormContainer>
  );
};

export default SignupForm;

import * as S from "./TeacherDetailLayout.style";
import TeacherDetailSection from "@organisms/Section/TeacherDetailSection";

function TeacherDetailLayout() {
  return (
    <S.TeacherDetailLayout>
      <TeacherDetailSection />
    </S.TeacherDetailLayout>
  );
}

export default TeacherDetailLayout;

import * as S from "./ArrowStyledIcon.style";

function ArrowStyledIcon({ onClick }: { onClick: () => void }) {
  return (
    <S.StyledIcon
      name="IconBackArrow"
      onClick={onClick}
      width="1.6rem"
      height="2.7rem"
    />
  );
}

export default ArrowStyledIcon;

import { Navigate, Route, Routes } from "react-router-dom";

import MainPage from "@pages/Main";

import Layout from "@templates/Layout/Layout";
import SignupPage from "@pages/Signup";
import FindIdResultPage from "@pages/FindIdResult";
import SearchResultPage from "@pages/SearchResult";
import SigninPage from "@pages/Signin";
import FindIdPwPage from "@pages/FindIdPw";
import ResetPwPage from "@pages/ResetPw";
import CategoriesPage from "@pages/Categories";
import TeacherDetailPage from "@pages/TeacherDetail";
import CounselPage from "@/components/pages/Counsel";
import ChargeCoinPage from "@/components/pages/ChargeCoin";

const Router = () => {
  return (
    <Routes>
      <Route element={<Layout hasHeader />}>
        <Route path="/login" Component={SigninPage} />
        <Route path="/" Component={MainPage} />
        <Route path="/search" Component={SearchResultPage} />

        {/* <Route path="/filter" Component={FilterPage} /> */}
        {/* <Route path="/filter/result" Component={FilterResultPage} /> */}
        <Route path="/categories/:category" Component={CategoriesPage} />
      </Route>

      <Route element={<Layout />}>
        <Route path="/signUp" Component={SignupPage} />
        <Route path="/find" Component={FindIdPwPage} />
        <Route path="/find/result" Component={FindIdResultPage} />
        <Route path="/reset" Component={ResetPwPage} />
        <Route path="/teacher/:teacherId" Component={TeacherDetailPage} />
        <Route path="/teacher/:teacherId/counsel" Component={CounselPage} />
        <Route path="/chargeCoin" Component={ChargeCoinPage} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;

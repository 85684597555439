import Button from "@atoms/Button/Button";
import Flex from "@atoms/Flex/Flex";
import Spacer from "@atoms/Spacer/Spacer";
import Text from "@atoms/Text/Text";
import * as S from "./NiceIdForm.style";
import ArrowStyledIcon from "@/components/atoms/Icon/ArrowStyledIcon";

const NiceIdForm = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => void;
  prevStep: () => void;
}) => {
  return (
    <S.NiceIdFormContainer>
      <Text typography="t24" fontWeight={600} color="main">
        본인인증
      </Text>
      <ArrowStyledIcon onClick={prevStep} />

      <Spacer space="10.4rem" />

      <Flex direction="column">
        <Text typography="t30" color="main">
          본인인증을 진행해주세요
        </Text>
        <Spacer space="2rem" />
        <Text typography="t16" color="grey56">
          회원가입을 위해 본인인증을 안전하게 진행해주세요.
        </Text>
      </Flex>
      <Spacer space="2.4rem" />
      <Button
        onClick={nextStep}
        color="next"
        width="100%"
        height="7rem"
        typography="t24"
        hover={false}
      >
        본인인증 하러가기
      </Button>
    </S.NiceIdFormContainer>
  );
};

export default NiceIdForm;
